.normascatai {
    background-color: white;
}

.cursoTeorico {
    background-color: #e8f3f8;
}

.reciclagem {
    background-color: white;
}

.vento {
    background-color: whitesmoke;
}

.velame {
    background-color: white;
}

.alturaComando {
    background-color: #e8f3f8;
}

.tamanhoAreaPouso {
    background-color: white;
}

.tempoSemSaltar {
    background-color: whitesmoke;
}

.oquefazer {
    background-color: white;
}