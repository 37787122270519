label {
    margin-left: 20px;
    margin-top: 20px;
}

button {
    font-weight: 300;
    padding: 12px 30px 12px 30px;
    font-size: large;
    border-radius: 5%;
    border: none;
    background-color: #2F4858;
    color: white;    
}

input {
    width: auto;
    max-width: 5%;
}

select {
    width: auto;
    max-width: 20%;
}

.container > h1 {
    margin-bottom: 0;
}

.output-group {
    margin-top: 20px;
}