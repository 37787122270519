.apresentacaocoach {
    display: flex;
}

.apresentacaocoach button {
    font-size: small;
    padding: 7px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 0;
}

.imgcoach {
    margin-left: 10px;
    margin-right: 10px;
}

.nomecoach {
    margin-top: 10px;
    margin-bottom: 10px;
}