input {
    text-align: left;
}

.caderneta th,td {
    border-color: black;
    border-width: 1px;
    border-style: solid;
}

td > input {
    border-style: none;
    padding-bottom: 15%;    
}

.TipoSalto {
    border-style: solid;
    padding-bottom: 2%;
    width: 50%;
}

.Descricao {
    border-style: solid;
    border-width: 1px;
    border-color: black;
    padding-bottom: 10%;
    width: 50%;
}

.InstrutorResponsavel {
    border-style: solid;
    width: 50%;
    padding-bottom: 2%;
}

select {
    border-style: solid;
    padding-bottom: 1%;
    width: 50%;
}
