.btn_home, .btn_back {
    margin-right: 15px;
    font-size: 18px;
    background-color:blueviolet;
    border: none;
    color: white;
    padding: 10px 17px;
    border-radius: 5px;
    font-weight: 700;
}

Footer {
    background-color: #2f4858;
    height: 7vh;
  }
  
.footer {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: end;
}
  
  .btn_back:hover, .btn_home:hover{
    cursor: pointer;
    opacity: 0.9;
    transition: opacity 0.5s ease-in;
  }

  footer a:hover {
    color: #cfcfcf;
  }  