
.HeaderAi{
    background-image: url("../../img/img22.JPG"); /*buscar imagem na pasta*/
    background-size: auto;
    height: 25vh;
    color: rgb(46 46 46);
    font-size: 40px;
    text-shadow: 2px 3px white;
    padding-left: 10px;
    width: 100%;
    display: flex;
    
  }
  