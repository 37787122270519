.btnVideoAulasRecomendados button {
    font-weight: 300;
    padding: 12px 30px 12px 30px;
    margin-bottom: 20px;
    font-size: large;
    border-radius: 5%;
    border: none;
    background-color: #2F4858;
    color: white;
}

button {
    margin-left: 10px;
  }

