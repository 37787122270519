.HeaderTools{
    background-image: url("../../img/img23.JPG"); /*buscar imagem na pasta*/
    background-size: auto;
    height: 20vh;
    color: rgb(46 46 46);
    text-shadow: 2px 3px white;
    padding-left: 10px;
    width: 100%;
    display: flex;    
  }

  .HeaderTools > h2 {
    font-size: 3rem;
    align-items: end;
  }



