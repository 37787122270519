Header {
    background-color: #2f4858;
    display: flex;
    justify-content: space-between;
    height: 7vh;
    align-items: center;
  }
  
  .header-links {
    color: white;
    margin-left: 15px;
    font-size: 20px;
    font-weight: 700;

  }
  
  .header-links > a {
    margin: 10px;
  }
  
  .header-button {
    margin-right: 15px;
    font-size: 18px;
    background-color:blueviolet;
    border: none;
    color: white;
    padding: 10px 17px;
    border-radius: 5px;
    font-weight: 700;
  }
  
  .header-button:hover{
    cursor: pointer;
    opacity: 0.9;
    transition: opacity 0.5s ease-in;
  }

  header a:hover {
    color: #cfcfcf;
  } 