

a {
  text-decoration: none;
  color: inherit;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

button:hover {
  opacity: 85%;
}

button {
  margin-left: 10px;
}

header button, footer button {
  margin: 0 0 0 0;
}

iframe {
  margin-left: 10px;
}

h1{
  color: rgb(46 46 46);
  font-size: 80px;
  text-shadow: 2px 3px white;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 0;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

h2{
  color: rgb(46 46 46);
  font-size: 70px;
  text-shadow: 2px 3px white;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 0;
  margin-bottom: 1%;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

h3, h4, h5, h6 {
  color: rgb(46 46 46);
  text-shadow: 2px 3px white;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  margin-top: 0;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

article, p {
  color: rgb(46 46 46);
  font-size: 1.2rem;
  text-shadow: none;
  padding-left: 10px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.image-1{
  background-image: url("./img/img1.JPG"); /*buscar imagem na pasta*/
  background-size: cover;
  height: 85vh;
  color: rgb(46 46 46);
  font-size: 50px;
  text-shadow: 2px 3px white;
  padding-left: 10px;

}

.image-1 > article {
  max-width: 40%;
  text-shadow: none;
  font-size: 1.8rem;
}

.image-1 > button {
  margin: 10px;
  border: none;
  color: #2F4858;
  background-color: rgb(100, 100, 100, 0);
  font-size: 22px;
}

.image-1 > button:hover {
  font-size: 25px;
}

.div_btn {
  display: flex;
  margin-top: 20px;  
}

.button-instruction{
  margin-left: 30%;
}

.button-cat{
  margin-left: 10%;
}

.acess button {
  font-weight: 300;
  padding: 12px 30px 12px 30px;
  margin-bottom: 20px;
  font-size: large;
  border-radius: 5%;
  border: none;
  background-color: #2F4858;
  color: white;
}

.class-extra button, .tools button, .coaches button{
  font-weight: 300;
  padding: 12px 30px 12px 30px;
  margin-bottom: 20px;
  font-size: large;
  border-radius: 5%;
  border: none;
  background-color: #2F4858;
  color: white;
  margin-left: 10px;
  margin-top: 10px;;
}

.class-extra {
  background-color: #e8f3f8;
  background-size: 90%;
}

.coaches{
  background-color: whitesmoke;
}

.tools {
  background-color: white;
}

.partners {
  background-color: whitesmoke;
  color: #2F4858;
}


