
img {
    max-width: 100px;
    max-height: 100px;
    object-fit: fill;
    margin-left: 20px;
  }

.partners {
    display:inline;
    border:2px solid white;
    margin: 4px;
    max-width:50px;
    max-height:50px;
    width: auto;
    height: auto;
}
