button {
    margin-top: 5px;
    margin-bottom: 5px;
}


.imageCatAi{
  background-image: url("../../img/img22.JPG"); /*buscar imagem na pasta*/
  background-size: cover;
  height: 75vh;
  color: rgb(46 46 46);
  font-size: 50px;
  text-shadow: 2px 3px white;
  padding-left: 10px;
}

.imageCatA{
  background-image: url("../../img/img21.JPG"); /*buscar imagem na pasta*/
  background-size: cover;
  height: 75vh;
  color: rgb(46 46 46);
  font-size: 50px;
  text-shadow: 2px 3px white;
  padding-left: 10px;
}



.imageCatAi > article {
  max-width: 40%;
  text-shadow: none;
  font-size: 1.8rem;
}

.bodyCatAi button {
  font-weight: 300;
  padding: 12px 30px 12px 30px;
  margin-bottom: 20px;
  font-size: large;
  border-radius: 5%;
  border: none;
  background-color: #2F4858;
  color: white;
}

  .normasAi{
    background-color: white;
    margin-bottom: 20px;
  }

  .apostilaAff{
    background-color: #e8f3f8;
    margin-bottom: 20px;
  }

  .provaAff{
    background-color: whitesmoke;
    margin-bottom: 20px;
  }

  .fichaProgAi{
    background-color: white;
    margin-bottom: 20px;
  }

  .provasAi {
    background-color: #e8f3f8;
    margin-bottom: 20px;
  }

  .extraCatAi {
    background-color: whitesmoke;
    margin-bottom: 20px;
  }

  .video-aulas {
    background-color: white;
    margin-bottom: 20px;
  }

  .video-aulas iframe {
    margin: 10px 10px 10px 10px;
  }

  .normasB, .normasC {
    background-color: white;
    margin-bottom: 20px;
  }

  .fichaProgB, .fichaProgC{
    background-color: #e8f3f8;
    margin-bottom: 20px;
  }

  .provasB, .provasC {
    background-color: white;
    margin-bottom: 20px;
  }

  .extraCatB, .extraCatC {
    background-color: whitesmoke;
    margin-bottom: 20px;
  }

  .normasD {
    background-color: white;
    margin-bottom: 20px;
  }

  .fichaProgVelame {
    background-color: #e8f3f8;
    margin-bottom: 20px;
  }

  .extraCatD {
    background-color: whitesmoke;
    margin-bottom: 20px;
  }